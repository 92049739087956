import React, { useEffect, useState, useRef, useContext, forwardRef } from 'react'
import { Link } from 'react-router-dom'
/* PRIME REACT */
import { Paginator } from 'primereact/paginator';
/* COMPONENTES */
import SweetButton from '../../components/SweetButton';
/* UTILITÁRIOS */
import SingMask from '../../utils/SingMask';

import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { ContentContext } from '../../Contexts/Contents/Context.js'
import { ExportXLS } from '../../services/subscriber/exportXls.js'
import MyButton from '../../components/MyButton.jsx';
import SingHelper from '../../utils/SingHelper.js';
import GeneratePdf from './generatePdf.js';

/* ESTILOS CSS */
import 'primereact/resources/themes/lara-light-purple/theme.css';
import useCase from './useCase.js';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import ShowToast from '../../components/Toast.jsx';
import { usePaginator } from '../../utils/Paginator.js'

const FragmentList = forwardRef((props, ref) => {
    //const {download} =  new GeneratePdf();
    const btnDelete = useRef();
    const containerRef = useRef(null);
    const { getTotalRecords } = usePaginator()
    const { location, getResult, isLoading, onResume, first, rows, template1, handlerDelete, broadCast, onPageChange } = useCase();

    const { setAuth, auth, provider_session, provider_current_client } = useContext(AuthContext)
    const { listData, getBroadcast } = useContext(ContentContext)
    const [isShow_paginator, setShow_paginator] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isReady, setIsReady] = useState(false);

    const [isDisabledButtonDeleteGroup, setIsDisabledButtonDeleteGroup] = useState(true);

    /* teste */
    const [checkboxValues, setCheckboxValues] = useState([]);

    const handleCheckboxChange = (event) => {
        const { checked, value } = event.target;
        if (checked)
            setCheckboxValues(prev => [...prev, value]);
        else
            setCheckboxValues(prev => prev.filter(item => item !== value));
    };

    const handleCheckAll = (e) => {
        setCheckboxValues([])
        const checkboxes = containerRef.current.querySelectorAll('.form-check-input');
        checkboxes.forEach(checkbox => {
            if (isCheckedAll == true)
                setCheckboxValues(prev => [...prev, checkbox.value]);
        });
    };

    useEffect(() => {
        if (btnDelete.current != undefined) {
            let RES = checkboxValues.length > 0 ? false : true
            setIsDisabledButtonDeleteGroup(RES)
        }

    }, [checkboxValues])

    useEffect(() => {
        if (getBroadcast != null)
            if (getBroadcast.command == "resetButtonDeleteGroup")
                setIsDisabledButtonDeleteGroup(true)
    }, [getBroadcast])

    useEffect(() => {
        if (isLoading == false && isReady == false)
            setIsReady(true)
    }, [isLoading])

    useEffect(() => {
        console.log('%c~ * ~ FRAGMENTLIST :: getResult foi atualizado ~ * ~', 'color: white; background-color: pink; padding: 4px;font-size: 18px');        
        setCheckboxValues([])
    }, [listData])

    return (
        <>
            {
                isLoading &&
                <CircleSpinnerOverlay
                    loading={true}
                    zIndex="9999"
                    message="Carregando..."
                    overlayColor="rgba(0,153,255,0.5)"
                />
            }
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title mb-0">Listagem de Inscritos</h4>
                        </div>
                        {/* end card header */}
                        {isReady == false ?
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                </button>
                            </div> : (
                                <div className="card-body">
                                    <div className="listjs-table" id="customerList">
                                        <div className="row g-4 mb-3">
                                            <div className="col-sm-auto">
                                                <div className="list-grid-nav hstack gap-1">
                                                    {/* <button type="button" className="btn btn-success add-btn" onClick={broadCast} id="create-btn" data-bs-toggle="modal" data-bs-target="#exampleModalgrid"><i className="ri-add-line align-bottom me-1"></i> Novo</button>
                                                    <SweetButton
                                                        props={{
                                                            button: {
                                                                disabled: isDisabledButtonDeleteGroup,
                                                                ref: btnDelete,
                                                                className: "btn btn-soft-danger",
                                                                icon: "ri-delete-bin-2-line",
                                                                text: ""
                                                            },
                                                            swlConfig: {
                                                                type: "dialog",
                                                                icon: "question",
                                                                title: "Deseja excluir os registros selecionados?",
                                                                text: "",
                                                                confirmButtonText: "Sim, estou ciente.",
                                                                confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                            },
                                                            actions: {
                                                                IfConfirmed: () => handlerDelete(checkboxValues), // Passar como uma função
                                                                IfDenied: ""
                                                            }
                                                        }} />                                                    

                                                    <button className="btn btn-soft-danger" onClick={() => new ExportXLS().generate({ template: "Inscritos", type: "xls", body_content: getResult, outPutFilename: "Turmas - total" })}><i className="ri-printer-line"></i></button>
                                                    */}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search"
                                                            placeholder="Procurar..." />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: '50px' }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" onChange={(e) => handleCheckAll(setCheckedAll(prev => !prev))} type="checkbox"
                                                                    id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th></th>
                                                        <th className="sort" data-sort="subscriber_id">id. inscrito&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="subscriber_name">Nome do inscrito&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="dt_birth">Data de nascimento&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="cpf">CPF&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="email">E-mail&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="whatsapp_number">Nº whatsapp&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="_updated_user_id">Atualizado por&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="_created_at">Criado em&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="subscriber_status_id">Status&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="seller_id">Líder responsável&nbsp;&nbsp;</th>
                                                        <th className="sort" data-sort="manager_id">Gestor responsável&nbsp;&nbsp;</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all" ref={containerRef}>
                                                    {!listData.length ? <>
                                                        <tr>
                                                            <td colSpan="14">
                                                                <div className="noresult">
                                                                    <div className="text-center">
                                                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                                                                            trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                                        <h5 className="mt-2">Sinto muito! Não há resultados.</h5>
                                                                        <p className="text-muted mb-0">Não encontramos nenhum registro em nosso banco de dados.</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </> :
                                                        (
                                                            listData.map(el => (

                                                                <tr key={el.subscriber_id}>
                                                                    <th scope="row">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" checked={checkboxValues.includes(el.subscriber_id)} name="chk_child" value={el.subscriber_id} onChange={handleCheckboxChange} />
                                                                        </div>
                                                                    </th>
                                                                    <td>
                                                                        <Link target='_whatsapp' to={`https://web.whatsapp.com/send?phone=55${el.whatsapp_number}&text=${SingHelper.stringToUrl(`Olá!, ${SingHelper.stringSplit(el.subscriber_name, { spliter: ' ', index: 0 })}, estamos felizes por você ter decidido viver o treinamento VIDA PRÓSPERA. Em breve você receberá seu ingresso. Aguarde!`)}`} title="Abrir conversa" className="btn btn-success btn-icon mr-2 rounded-circle waves-effect waves-themed" data-filter-tags="application intel marketing dashboard">
                                                                            <i class="mdi mdi-whatsapp"></i>
                                                                        </Link>&nbsp;
                                                                        <MyButton
                                                                            name={`myButton_${el.subscription_id}`}
                                                                            //loading={isActiveLoader}
                                                                            //disabled={isActiveLoader}
                                                                            //activeButton={activeButton}
                                                                            onClick={() => GeneratePdf.download(el, location.state.stateData)}
                                                                            icon="mdi mdi-cloud-download-outline"
                                                                            btnClass="btn btn-info btn-icon mr-2 rounded-circle waves-effect waves-themed" />
                                                                    </td>
                                                                    <td nowrap="nowrap">{el.subscriber_id}</td>
                                                                    <td nowrap="nowrap">{el.subscriber_name}</td>
                                                                    <td nowrap="nowrap">{SingMask.toDate(el.dt_birth, "dmY")}</td>
                                                                    <td nowrap="nowrap">{SingMask.toDocument(el.cpf, "M")}</td>
                                                                    <td nowrap="nowrap">{el.email}</td>
                                                                    <td nowrap="nowrap">{SingMask.toPhone(el.whatsapp_number)}</td>
                                                                    <td nowrap="nowrap">{el._updated_user_name}</td>
                                                                    <td nowrap="nowrap">{SingMask.toDate(el._created_at, "dmYHi")}</td>
                                                                    <td nowrap="nowrap">{el.subscriber_status_name}</td>
                                                                    <td nowrap="nowrap">{el.seller_name}</td>
                                                                    <td nowrap="nowrap">{el.manager_name}</td>
                                                                    <td nowrap="nowrap">
                                                                        <div className="dropdown d-inline-block">
                                                                            <button className="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <i className="ri-more-fill align-middle"></i>
                                                                            </button>
                                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                                {/* <li><a href="#!" className="dropdown-item"><i className="ri-eye-fill align-bottom me-2 text-muted"></i> View</a></li> */}
                                                                                <li>
                                                                                    <Link to={`/admin/register/subscriber/${el.subscriber_id}/patch`} state={{ stateData: el }} title="Listar inscrições" className="dropdown-item edit-item-btn" data-filter-tags="application intel marketing dashboard">
                                                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Editar
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <SweetButton props={{
                                                                                        button: {
                                                                                            className: "dropdown-item remove-item-btn",
                                                                                            icon: "ri-delete-bin-fill align-bottom me-2 text-muted",
                                                                                            text: "Excluir"
                                                                                        },
                                                                                        swlConfig: {
                                                                                            type: "dialog",
                                                                                            icon: "question",
                                                                                            title: "Deseja excluir",
                                                                                            text: `Cód. ${el.subscriber_id} - ${el.subscriber_id}?`,
                                                                                            confirmButtonText: "Sim, excluir mesmo assim.",
                                                                                            confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                                        },
                                                                                        actions: {
                                                                                            IfConfirmed: () => handlerDelete(el.subscriber_id), // Passar como uma função
                                                                                            IfDenied: ""
                                                                                        }
                                                                                    }} />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td></tr>
                                                            )))
                                                    }
                                                </tbody>

                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th className="sort" data-sort="subscriber_id">id. inscrito</th>
                                                        <th className="sort" data-sort="subscriber_name">Nome do inscrito</th>
                                                        <th className="sort" data-sort="dt_birth">Data de nascimento</th>
                                                        <th className="sort" data-sort="cpf">CPF</th>
                                                        <th className="sort" data-sort="email">E-mail</th>
                                                        <th className="sort" data-sort="whatsapp_number">Nº whatsapp</th>
                                                        <th className="sort" data-sort="_updated_user_id">Atualizado por</th>
                                                        <th className="sort" data-sort="_created_at">Criado em</th>
                                                        <th className="sort" data-sort="subscriber_status_id">Status</th>
                                                        <th className="sort" data-sort="seller_id">Líder responsável</th>
                                                        <th className="sort" data-sort="manager_id">Gestor responsável</th>

                                                    </tr>
                                                </tfoot>
                                            </table>


                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {isShow_paginator &&
                                                    <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                    </div>

                </div>

            </div>

            <ShowToast resume={onResume}></ShowToast>
        </>
    );
})

export default FragmentList;