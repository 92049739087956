import { Route, Router, Routes } from 'react-router-dom';

import Pearson from './presentation/pearson/index';
import Login from './presentation/login';
import Branch from './presentation/branch';
import Class from './presentation/class';
import Subscriber from './presentation/subscriber';
import Change from './presentation/change';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/ingresso" element={<Ingresso />} /> */}
        <Route path="/admin/register/pearson" element={<Pearson />}>
          <Route exact path=":action" element={<Pearson />} />
          <Route path=":action/:action" element={<Pearson />} />
        </Route>
        <Route path="/admin/register/branch" element={<Branch />}>
          <Route exact path=":action" element={<Branch />} />
          <Route path=":action/:action" element={<Branch />} />
        </Route>
        <Route path="/admin/register/class" element={<Class />}>
          <Route exact path=":action" element={<Class />} />
          <Route path=":action/:action" element={<Class />} />
        </Route>
        
        <Route path="/admin/register/subscriber" element={<Subscriber />}>
          <Route exact path=":action" element={<Subscriber />} />
          <Route path=":action/:action" element={<Subscriber />} />
        </Route>

        <Route path="/admin/change" element={<Change />}>
          <Route exact path=":client_id" element={<Change />} />
        </Route>

        <Route path="/admin/welcome" element={<Pearson />}/>
       
        <Route path="*" element={<Login />} /> 
      </Routes>
    </div>
  );
}

export default App;
