
		
		import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
		import { Link, useParams, useNavigate } from 'react-router-dom'

		/* COMPONENTES */
		import MyButton from '../../components/MyButton'

		/* AUXILIARES */
		import Alert from '../../components/Alert.jsx';		
		import { AuthContext } from '../../Contexts/Auth/AuthContext.js';
		import Permission from '../../utils/Permission.js';		
		import UCCreateClass from './useCaseCreate.js';
		import CustomInput from '../../components/CustomInput.jsx';	
		import ShowToast from '../../components/Toast.jsx';
		import { CircleSpinnerOverlay } from 'react-spinner-overlay'	

		/* select personalizados */		
		import SelectSingleDbCourse from '../../components/selectSingleDbCourse.jsx';
import SelectSingleDbClient from '../../components/selectSingleDbClient.jsx';
import SelectSingleDbLocation from '../../components/selectSingleDbLocation.jsx';
import SelectSingleDbClassModality from '../../components/selectSingleDbClassModality.jsx';
import SelectSingleDbUser from '../../components/selectSingleDbUser.jsx';
import SelectSingleDbStatus from '../../components/selectSingleDbStatus.jsx';
import SelectSingleDbCity from '../../components/selectSingleDbCity.jsx';
import SelectSingleDbCertificateConfig from '../../components/selectSingleDbCertificateConfig.jsx';


		const FragmentForm = forwardRef((props, ref) => {
			const modalRef = useRef();
			const buttonRef = useRef(null);

			const openModal = () => {
				if (modalRef.current) {
					const modal = new window.bootstrap.Modal(modalRef.current);
					modal.show();
				}
			};

			const closeModal = () => {
				buttonRef.current.click(); // Simula o clique no botão
			};
			const {				
				formik,
				onResume,
				isSubmiting,
				editData,
				action,
				handleClickCancel
			} = UCCreateClass();
			
			useEffect(() => {
				console.log("useEffect onResume", onResume);
				if (onResume != null)
					closeModal()
			}, [onResume])
		
			useEffect(() => {
				console.log("useEffect onResume", onResume);
				if (editData != null)
					openModal();
			}, [editData])

			return (
				<>
					{
						isSubmiting &&
						<CircleSpinnerOverlay
							loading={true}
							zIndex="9999"
							message="Carregando..."
							overlayColor="rgba(0,153,255,0.5)"
						/>
					}
					{
						action == "patch" && editData == null ?
							<Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
							: (
								editData != null &&
								<>
		
								</>
							)
					}
					<div className="modal fade" id="exampleModalgrid" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalgridLabel">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalgridLabel">
										{
										action == "patch" ? "Edição" : "Criação"
										}
									</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" ref={buttonRef} aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<form onSubmit={formik.handleSubmit} className="validate m-t needs-validation" noValidate id="form-create-update">
										<div className="panel-content">
											<div className="form-row">
											<div className="col-md-auto mb-3">
								<CustomInput type="input-integer" field="class_number" id="validationCustom01" required placeholder="Número da turma" label='Número da turma' formik={formik} args={{ action: action }} />
							</div>
<div className="col-md-auto mb-3">
								<SelectSingleDbCourse	field="course_id" label="Nome do curso" formik={formik}/>
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-integer" field="vacancy" id="validationCustom01" required placeholder="Número de vagas" label='Vagas' formik={formik} args={{ action: action }} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-datetime" field="dt_start" id="validationCustom01" required placeholder="Selecione uma data" label='Data início' formik={formik} args={{ action: action, mask: "99/99/9999" }} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-datetime" field="dt_end" id="validationCustom01" required placeholder="Data fim" label='Data fim' formik={formik} args={{ action: action, mask: "99/99/9999" }} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-real" field="price_cash" id="validationCustom01" required placeholder="Investimento a vista" label='Investimento a vista' formik={formik} args={{ action: action}} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-real" field="price_split" id="validationCustom01" required placeholder="Investimento parcelado" label='Investimento parcelado' formik={formik} args={{ action: action}} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="input-integer" field="workload" id="validationCustom01" required placeholder="Carga horária" label='Carga horária' formik={formik} args={{ action: action }} />
							</div>
<div className="col-md-auto mb-3">
								<CustomInput type="textarea" field="observation" id="validationCustom01"  placeholder="Observação" label="Observação" formik={formik} args={{ action: action }} />
							</div>
<div className="col-md-auto mb-3">
								<SelectSingleDbLocation	field="location_id" label="Local do curso" formik={formik}/>
							</div>
<div className="col-md-auto mb-3">
								<SelectSingleDbClassModality	field="class_modality_id" label="Modalidade" formik={formik}/>
							</div>
{
								action == "patch" && <div className="col-md-auto mb-3">
								<SelectSingleDbStatus	field="class_status_id" label="Status da turma" formik={formik}/>
							</div>

						}<div className="col-md-auto mb-3">
								<SelectSingleDbCity	field="city_id" label="Realizado em" formik={formik}/>
							</div>
<div className="col-md-auto mb-3">
								<SelectSingleDbCertificateConfig	field="student_certificate_config_id" label="Configuração certificado aluno" formik={formik}/>
							</div>
<div className="col-md-auto mb-3">
								<SelectSingleDbCertificateConfig	field="icp_certificate_config_id" label="Config. certificado ICP" formik={formik}/>
							</div>

											</div>
										</div>								
										<div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
			{
				action == "patch" &&
				<MyButton
					name="btnCancel"
					text="Cancelar"
					type="button"					
					onClick={(el) => handleClickCancel("btnCancel")}
					icon="fal fa-times"
					btnClass="btn btn-default ml-auto" />
			}
			{
				action == "patch" &&
				<MyButton
					name="btnPatch"
					text="Novo"
					type="button"					
					onClick={(el) => handleClickCancel("btnNew")}
					icon="fal fa-plus"
					btnClass="btn btn-success ml-2" />
			}
			<MyButton
				name="btnSave"
				text="Salvar"
				type="submit"
				loading={isSubmiting}				
				icon="fal fa-save"
				btnClass="btn btn-primary ml-2" />

		</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<ShowToast resume={onResume}></ShowToast>
				</>
			);
		})
		
		export default FragmentForm;
		